import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import FindSolicitorBody from 'components/FindSolicitor/Body';
import FindSolicitorHero from 'components/FindSolicitor/Hero';

const FindASolicitor: React.FC = () => {
  return (
    <Layout>
      <SEO title="Find a Conveyancing Solicitor in Ireland" />
      <FindSolicitorHero />
      <FindSolicitorBody />
    </Layout>
  );
};

export default FindASolicitor;
