import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FormatHtml from 'components/utils/FormatHtml';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

const FindSolicitorBody: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "find solicitor body" } }) {
        html
      }
    }
  `);

  return (
    <Container section>
        <TitleSection title='Why Choose A Solicitor With CU Home Hub?' center />
        <FormatHtml content={markdownRemark.html} />
    </Container>
  );
};

export default FindSolicitorBody;
